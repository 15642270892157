import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Do = makeShortcode("Do");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "percentages"
    }}>{`Percentages`}</h1>
    <p>{`When you need to indicate percentages, follow these guidelines:`}</p>
    <ul>
      <li parentName="ul">{`In running text and headers spell out the word percent instead of using the % symbol.`}</li>
    </ul>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Workday has a 98 percent customer satisfaction rating.`}</li>
        <li parentName="ul">{`Workday Achieves 98 Percent Customer Satisfaction`}</li>
      </ul>
    </Do>
    <p>{`In tables, charts, graphs, scientific-based copy, quotes, or when space is limited, use the percent
symbol.`}</p>
    <Do mdxType="Do">
      <ul>
        <li parentName="ul">{`Workday customer satisfaction: 98%`}</li>
        <li parentName="ul">{`“Workday has a customer satisfaction rating of 98%,” said Workday Chairman Dave Duffield.`}</li>
      </ul>
    </Do>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      